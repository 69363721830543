import '../../assets/style/homepage.css';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    IcBell,
    IcClockCircle,
    IcLogoutCircle
} from '../../assets/icons';
import bg_pattern from '../../assets/images/bg_pattern.png';
import { Sidebar, ModalAlasanDitolak, MonthYearPicker } from '../../components';
import { getNotifikasi } from '../../store/actions/notifikasiAction';
import {
    getDataDashboard,
    getIzinCutiDashboard,
    getGajiDashboard,
} from '../../store/actions/dashboardAction';
import { editCutiStatus, editIzinStatus } from '../../store/actions/izinCutiAction';
import { editStatusLembur, editStatusReimburse } from '../../store/actions/gajiAction';
import { formatDateFns } from '../../utils/formatDateFns';
import Swal from 'sweetalert2';
import useDateFilter from '../../hooks/useDateFilter';
import { formatRupiah } from '../../utils/formatRupiah';

const Home = ({
    dataNotifikasi,
    dataDashboard,
    dataIzinCutiDashboard,
    dataGajiDashboard,
    getNotifikasi,
    getDataDashboard,
    getIzinCutiDashboard,
    getGajiDashboard,
    editCutiStatus,
    editIzinStatus,
    editStatusLembur,
    editStatusReimburse,
}) => {
    const notifCount = dataNotifikasi.filter(
        (item) => item.status === '1'
    ).length;
    const { izin_cuti, terlambat } = dataDashboard;

    const [date, setDate] = useDateFilter();
    const [openDitolak, setOpenDitolak] = useState(false);
    const [alasan, setAlasan] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        getNotifikasi();
        document.title = 'AlanHR | Home';
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const params = `?bulan_tahun=${formatDateFns(date, 'MM-yyyy')}`;
        getDataDashboard(params);
        getDataIzinCutiDashboard();
        getDataGajiDashboard();
        // eslint-disable-next-line
    }, [date.getMonth(), date.getFullYear()]);

    const getDataIzinCutiDashboard = () => {
        const params = `?bulan_tahun=${formatDateFns(date, 'MM-yyyy')}&limit=4`;
        getIzinCutiDashboard(params);
    };

    const getDataGajiDashboard = () => {
        const params = `?bulan_tahun=${formatDateFns(date, 'MM-yyyy')}&limit=2`;
        getGajiDashboard(params);
    };

    const handleUpdateIzinCutiStatus = (item, status) => {
        const type = item.type;

        switch (type) {
            case 'izin':
                editIzinStatus({ ...item, status, alasan })
                    .then((res) => {
                        Swal.fire({
                            title: 'Success',
                            text: 'Data Izin Berhasil Disimpan',
                            icon: 'success',
                            iconColor: '#00ACEE',
                            timer: 2000,
                            showConfirmButton: false
                        });

                        getDataIzinCutiDashboard();
                    })
                    .catch((err) => {
                        Swal.fire({
                            title: 'Error',
                            text: err.message,
                            icon: 'error',
                            timer: 2000,
                            showConfirmButton: false
                        });
                    });
                break;
            case 'cuti':
                editCutiStatus({ ...item, status, alasan })
                    .then((res) => {
                        Swal.fire({
                            title: 'Success',
                            text: 'Data Cuti Berhasil Disimpan',
                            icon: 'success',
                            iconColor: '#00ACEE',
                            timer: 2000,
                            showConfirmButton: false
                        });
                        getDataIzinCutiDashboard();
                    })
                    .catch((err) => {
                        Swal.fire({
                            title: 'Error',
                            text: err.message,
                            icon: 'error',
                            timer: 2000,
                            showConfirmButton: false
                        });
                    });
                break;
            default:
                break;
        }

        setOpenDitolak(false);
    };

    const handleUpdateGajiStatus = (item, status) => {
        const type = item.type;

        switch (type) {
            case 'lembur':
                if (status === 'ditolak' && !item.keterangan) {
                    item.keterangan = 'tidak ada keterangan';
                }
                editStatusLembur({ ...item, status })
                    .then((res) => {
                        Swal.fire({
                            title: 'Success',
                            text: 'Data Lembur Berhasil Disimpan',
                            icon: 'success',
                            iconColor: '#00ACEE',
                            timer: 2000,
                            showConfirmButton: false
                        });

                        getDataGajiDashboard();
                    })
                    .catch((err) => {
                        Swal.fire({
                            title: 'Error',
                            text: err.message,
                            icon: 'error',
                            timer: 2000,
                            showConfirmButton: false
                        });
                    });
                break;
            case 'reimburse':
                editStatusReimburse({ ...item, status, alasan })
                    .then((res) => {
                        Swal.fire({
                            title: 'Success',
                            text: 'Data Reimburse Berhasil Disimpan',
                            icon: 'success',
                            iconColor: '#00ACEE',
                            timer: 2000,
                            showConfirmButton: false
                        });
                        getDataGajiDashboard();
                    })
                    .catch((err) => {
                        Swal.fire({
                            title: 'Error',
                            text: err.message,
                            icon: 'error',
                            timer: 2000,
                            showConfirmButton: false
                        });
                    });
                break;
            default:
                break;
        }

        setOpenDitolak(false);
    };

    const handleOpenDitolak = (item) => {
        setSelectedItem(item);
        setOpenDitolak(true);
    };

    const handleCloseDitolak = () => {
        setOpenDitolak(false);
    };

    const handleAlasanDitolak = (e) => {
        setAlasan(e.target.value);
    };

    return (
        <div className="wrapper">
            <Sidebar isHome={true}>
                <div className="home-user-info-container">
                    <div className="d-flex justify-content-between">
                        <div className="home-user-info">
                            <h1>Dashboard</h1>
                        </div>
                        <Link to="/notifikasi" className="notification-icon">
                            <div className="notification-count">
                                {notifCount}
                            </div>
                            <img src={IcBell} alt="bell" />
                        </Link>
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-center container-home  ">
                    <img
                        src={bg_pattern}
                        alt="bg_pattern"
                        className="bg-pattern"
                    />

                    <div className="home-card-wrapper">
                        <MonthYearPicker date={date} setDate={setDate} />

                        <div className="counter-card-container">
                            <Link to="daftar_izin_cuti" className="izin-cuti-card counter-card">
                                <div className="counter-container">
                                    <img src={IcLogoutCircle} alt="" />
                                    <p>{izin_cuti}</p>
                                </div>
                                <p>Izin & Cuti</p>
                            </Link>
                            <Link to="/daftar_terlambat" className="terlambat-card counter-card">
                                <div className="counter-container">
                                    <img src={IcClockCircle} alt="" />
                                    <p>{terlambat}</p>
                                </div>
                                <p>Telat</p>
                            </Link>
                        </div>

                        <div className="izin-cuti-container">
                            <p className="izin-cuti-title">
                                Permintaan Izin & Cuti
                            </p>
                            {dataIzinCutiDashboard.length > 0 ? (
                                <>
                                    <div className="izin-cuti-data-container">
                                        {dataIzinCutiDashboard.map(
                                            (item, index) => (
                                                <div className="izin-cuti-data" key={index}>
                                                    <div className="izin-cuti-data-content">
                                                        <p className="izin-cuti-data-nama">
                                                            {item.name}
                                                        </p>
                                                        <p title={item.keterangan}>{item.keterangan}</p>
                                                        <p>
                                                            Diajukan
                                                            tanggal:{' '}
                                                            <span className="izin-cuti-data-tanggal">
                                                                {formatDateFns(
                                                                    item.created_at,
                                                                    'd MMMM yyyy'
                                                                )}
                                                            </span>
                                                        </p>
                                                        <p>
                                                            Untuk tanggal:{' '}
                                                            <span className="izin-cuti-data-tanggal">
                                                                {formatDateFns(
                                                                    item.tanggal_mulai,
                                                                    'd MMMM yyyy'
                                                                )}
                                                                {item.tanggal_mulai !==
                                                                    item.tanggal_akhir
                                                                    ? ` - ${formatDateFns(
                                                                        item.tanggal_akhir,
                                                                        'd MMMM yyyy'
                                                                    )}`
                                                                    : ''}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="izin-cuti-data-action">
                                                        <button
                                                            className="izin-cuti-data-izin"
                                                            onClick={(e) =>
                                                                handleUpdateIzinCutiStatus(
                                                                    item,
                                                                    'diizinkan'
                                                                )
                                                            }
                                                        >
                                                            Izinkan
                                                        </button>
                                                        <button
                                                            className="izin-cuti-data-tolak"
                                                            onClick={(e) =>
                                                                handleOpenDitolak(
                                                                    item
                                                                )
                                                            }
                                                        >
                                                            Tolak
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <Link
                                        to="/izin_cuti"
                                        className="izin-cuti-lihat-lainnya"
                                    >
                                        Lihat Lainnya
                                    </Link>
                                </>
                            ) : (
                                <p className="pesan-no-data">
                                    Tidak ada pengajuan izin & cuti yang
                                    ditujukan untuk bulan ini
                                </p>
                            )}
                        </div>

                        <div className="izin-cuti-container">
                            <p className="izin-cuti-title">
                                Permintaan Lembur & Reimburse
                            </p>
                            {dataGajiDashboard.length > 0 ? (
                                <>
                                    <div className="izin-cuti-data-container">
                                        {dataGajiDashboard.map(
                                            (item, index) => (
                                                <div className="izin-cuti-data" key={index}>
                                                    <div className="izin-cuti-data-content">
                                                        <p className="izin-cuti-data-nama">
                                                            {item.name}
                                                        </p>
                                                        <p title={item.keterangan}>{item.keterangan}</p>
                                                        {item.type === 'lembur' && <p>{item.upah_lembur !== 0 ? formatRupiah(item.upah_lembur) : ''} ({item.total_jam} jam {item.jenis_hari})</p>}
                                                        {item.type === 'reimburse' && <p>{formatRupiah(item.nominal)}</p>}
                                                        <p>
                                                            <span className="izin-cuti-data-tanggal">
                                                                {formatDateFns(
                                                                    item.tanggal,
                                                                    'd MMMM yyyy'
                                                                )}
                                                                {item.type === 'lembur' && <> {' '}{item.waktu_mulai?.replace(/:\d\d$/, '')} - {item.waktu_akhir?.replace(/:\d\d$/, '')}</>}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="izin-cuti-data-action">
                                                        <button
                                                            className="izin-cuti-data-izin"
                                                            onClick={(e) =>
                                                                handleUpdateGajiStatus(
                                                                    item,
                                                                    'diterima'
                                                                )
                                                            }
                                                        >
                                                            Terima
                                                        </button>
                                                        <button
                                                            className="izin-cuti-data-tolak"
                                                            onClick={(e) => {
                                                                if (item.type === 'lembur') {
                                                                    handleUpdateGajiStatus(item, 'ditolak');
                                                                } else {
                                                                    handleOpenDitolak(
                                                                        item
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            Tolak
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <Link
                                        to="/gaji"
                                        className="izin-cuti-lihat-lainnya"
                                    >
                                        Lihat Lainnya
                                    </Link>
                                </>
                            ) : (
                                <p className="pesan-no-data">
                                    Tidak ada pengajuan lembur & reimburse yang
                                    ditujukan untuk bulan ini
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </Sidebar >
            {selectedItem &&
                <ModalAlasanDitolak
                    alasan={alasan}
                    handleAlasanDitolak={handleAlasanDitolak}
                    handleCloseDitolak={handleCloseDitolak}
                    handleUpdateStatus={selectedItem.type === 'izin' || selectedItem.type === 'cuti' ? handleUpdateIzinCutiStatus : handleUpdateGajiStatus}
                    openDitolak={openDitolak}
                    selectedItem={selectedItem}
                />
            }
        </div >
    );
};

const mapStateToProps = (state) => {
    return {
        dataNotifikasi: state.notifikasi.dataNotifikasi,
        dataDashboard: state.dashboard.dataDashboard,
        dataIzinCutiDashboard: state.dashboard.dataIzinCutiDashboard,
        dataGajiDashboard: state.dashboard.dataGajiDashboard,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getNotifikasi: () => dispatch(getNotifikasi()),
        getDataDashboard: (params) => dispatch(getDataDashboard(params)),
        getIzinCutiDashboard: (params) =>
            dispatch(getIzinCutiDashboard(params)),
        getGajiDashboard: (params) => dispatch(getGajiDashboard(params)),
        editCutiStatus: (params) => dispatch(editCutiStatus(params)),
        editIzinStatus: (params) => dispatch(editIzinStatus(params)),
        editStatusLembur: (params) => dispatch(editStatusLembur(params)),
        editStatusReimburse: (params) => dispatch(editStatusReimburse(params)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
