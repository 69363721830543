import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Sidebar, CardMessages, Title } from '../../components';
import '../../assets/style/absensipage.css';
import { connect } from 'react-redux';
import {
    getNotifikasi,
    editNotifikasi,
    clearNotifikasi
} from '../../store/actions/notifikasiAction';
import {
    IcHome,
    IcDocument,
    IcWallet,
    IcLogo,
    IcTrash,
} from '../../assets';
import Swal from 'sweetalert2';
const Notifikasi = ({ getNotifikasi }) => {
    const dataNotifikasi = useSelector(
        (state) => state.notifikasi.dataNotifikasi
    );
    const dispatch = useDispatch();

    const getData = () => {
        getNotifikasi();
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, []);

    const pageImage = (pagename) => {
        switch (pagename.toLowerCase()) {
            case 'izin':
            case 'cuti':
                return IcDocument;
            case 'lembur':
            case 'reimburse':
                return IcWallet;
            default:
                return IcHome;
        }
    };

    const getUrl = (pagename, datetime) => {
        const [date,] = datetime.split(' ');
        const [year, month,] = date.split('-');
        const bulan_tahun = `${month}-${year}`;
        const tipe = pagename.toLowerCase();
        switch (tipe) {
            case 'izin':
            case 'cuti':
                return `/izin_cuti?bulan_tahun=${bulan_tahun}&tipe=${tipe}`;
            case 'lembur':
            case 'reimburse':
                return `/gaji?bulan_tahun=${bulan_tahun}&tipe=${tipe}`;
            default:
                return '/';
        }
    }

    const handleRead = async (id) => {
        try {
            await dispatch(editNotifikasi(id));
        } catch (error) {
            console.log(error);
        }
    };

    const handleClearNotifikasi = async () => {
        try {
            if (dataNotifikasi.length === 0) {
                Swal.fire({
                    title: 'Error',
                    text: 'Tidak Ada Data Notifikasi Yang Dapat Dibersihkan',
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false
                });
                return;
            }

            await dispatch(clearNotifikasi());

            Swal.fire({
                title: 'Success',
                text: 'Data Notifikasi Berhasil Dibersihkan',
                icon: 'success',
                iconColor: '#00ACEE',
                timer: 2000,
                showConfirmButton: false
            });

            getData();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div>
            <Sidebar>
                <Title title="Notifikasi" icon customIcon={IcTrash} handleIcon={handleClearNotifikasi} iconProps={{
                    style: { cursor: 'pointer' }
                }} />

                <div className="home-card-container">
                    {dataNotifikasi.length > 0 ? dataNotifikasi.map((item, index) => (
                        <CardMessages
                            to={getUrl(item.link, item.created_at)}
                            name={item?.keterangan}
                            message={item?.message}
                            time={item?.created_at}
                            picture={pageImage(item?.link)}
                            isRead={item?.status === 'dikirim' ? true : false}
                            onClickCard={() => {
                                handleRead(item?.id);
                            }}
                        />
                    )) : (
                        <div className="notif-not-found">
                            <img src={IcLogo} alt="Logo" />
                            <p>Saat ini belum ada notifikasi yang perlu Anda perhatikan</p>
                        </div>
                    )}
                </div>
            </Sidebar>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        dataNotifikasi: state.notifikasi.dataNotifikasi
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getNotifikasi: () => dispatch(getNotifikasi()),
        clearNotifikasi: () => dispatch(clearNotifikasi()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Notifikasi);
