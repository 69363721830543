import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';

import auth from './authReducer';
import user from './userReducer';
import absen from './absenReducer';
import izinCutiReducer from './izinCutiReducer';
import LainnyaReducer from './lainnyaReducer';
import gajiReducer from './gajiReducer';
import notifikasi from './notifikasiReducer';
import akunReducer from './akunReducer';
import lembur from './lemburReducer';
import reimburse from './reimburseReducer';
import dashboard from './dashboardReducer';

export const history = createBrowserHistory();
export default combineReducers({
    router: connectRouter(history),
    auth,
    user,
    absen,
    lembur,
    reimburse,
    izin: izinCutiReducer,
    gaji: gajiReducer,
    notifikasi,
    lainnya: LainnyaReducer,
    akun: akunReducer,
    dashboard,
});
