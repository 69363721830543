import { useState, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Sidebar, Title } from '../../components';
import '../../assets/style/absensipage.css';
import {
    IcAddUser,
    IcRightArrow,
    IcLock
} from '../../assets/icons';
import { useHistory, Link } from 'react-router-dom';
import { getDetailKaryawan, logout } from '../../store/actions/akunAction';
import '../../assets/style/akunIndex.css';
import Swal from 'sweetalert2';
import { Avatar, Button } from '@material-ui/core';
import { useEffect } from 'react';

const AkunIndex = () => {
    const { id } = localStorage.getItem('users_code');
    const history = useHistory();
    const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState(null);

    const [state, setState] = useState({
        jadwal_kerja: [],
        jenis_kelamin: '',
        status: ''
    });

    const dispatch = useDispatch();

    useEffect(() => {
        getData()
    });

    const handleSubmit = () => {
        dispatch(logout())
            .then(() => {
                Swal.fire('Success!', 'Logout berhasil!', 'success');
                history.push('/login');
            })
            .catch((err) => {
                // let error = err?.response?.data;
                history.push('/login');
                // Swal.fire(
                //     Array.isArray(error?.data)
                //         ? error?.data[0]
                //         : 'Logout gagal, coba beberapa saat lagi',
                //     false
                // );
            });
    };

    const getData = () => {
        const handler = (e) => {
            e.preventDefault();
            console.log('we are being triggered :D');
            setSupportsPWA(true);
            setPromptInstall(e);
        };
        window.addEventListener('beforeinstallprompt', handler);

        return () => window.removeEventListener('transitionend', handler);
    }

    useLayoutEffect(() => {
        getData();
        getDetailKaryawan(id).then((res) => {
            let data = res.data?.data[0];
            setState((prev) => ({
                ...prev,
                ...data
            }));
        });
        // eslint-disable-next-line
    }, []);

    const installApp = (evt) => {
        evt.preventDefault();
        if (!promptInstall) {
            return;
        }
        promptInstall.prompt();
    };

    return (
        <div>
            <Sidebar>
                <Title title="Profile" />
                <div class="container mt-55 mb-120">
                    <div class="d-flex justify-content-center mx-auto w-85 h-85 radius-circle border-2-blue">
                        <Avatar
                            src={state.foto_profile}
                            alt="preview foto"
                            variant="rounded"
                            style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '100%'
                            }}
                        />
                    </div>
                    <div class="d-flex justify-content-center mt-3">
                        <h6>{state.name}</h6> <br></br>
                    </div>

                    <div class="d-flex justify-content-center text-primary">
                        <h8>{state.unit_kerja}</h8>
                    </div>

                    <div class="d-flex justify-content-center ">
                        <h8>{state.email}</h8>
                    </div>

                    <div class="mt-45 mb-3">
                        <h5 className="text-black font-weight-semibold mb-2">
                            Pengaturan Akun
                        </h5>
                    </div>
                    <div>
                        <Link to="/akun/UbahProfile" class="text-dark">
                            <div className="d-flex flex-row align-items-center justify-contents-between mt-2">
                                <img
                                    src={IcAddUser}
                                    alt="add_user"
                                    className="mr-10"
                                />
                                <div class="container mt-2">
                                    <h6 className="text-black font-weight-normal">
                                        Ubah Profile
                                    </h6>
                                </div>

                                <div class="">
                                    <img
                                        src={IcRightArrow}
                                        alt="rightArrow"
                                        class="float-right mt-1 mr-0"
                                    />
                                </div>
                            </div>
                        </Link>

                        {/* Ubah Kata Sandi */}
                        <Link to="/akun/UbahKataSandi" class="text-dark mt-3">
                            <div className="d-flex flex-row align-items-center justify-contents-between mt-2">
                                <img
                                    src={IcLock}
                                    alt="lock"
                                    className="
                                    mr-10"
                                />
                                <div class="container mt-2">
                                    <h6 className="text-black font-weight-normal">
                                        Ubah Kata Sandi
                                    </h6>
                                </div>
                                <div class="">
                                    <img
                                        src={IcRightArrow}
                                        alt="rightArrow"
                                        class="float-right mt-1 mr-0"
                                    />
                                </div>
                            </div>
                        </Link>

                        {/* Perjanjian Kerja*/}
                        {/* <Link to="/akun/PerjanjianKerja" class="text-dark mt-3">
                            <div className="d-flex flex-row align-items-center justify-contents-between mt-2">
                                <img
                                    src={IcEdit}
                                    alt="edit"
                                    className="mr-10 ml-0 pl-0"
                                />

                                <div class="container mt-2">
                                    <h6 className="text-black font-weight-normal">
                                        Perjanjian Kerja
                                    </h6>
                                </div>
                                <div class="">
                                    <img
                                        src={IcRightArrow}
                                        alt="rightArrow"
                                        class="float-right mt-1 mr-0"
                                    />
                                </div>
                            </div>
                        </Link> */}

                        {/* FAQ */}
                        {/* <Link to="/akun/FAQ" class="text-dark">
                            <div className="d-flex flex-row align-items-center justify-contents-between mt-2">
                                <img
                                    src={IcSearch}
                                    alt="search"
                                    className="
                                    mr-10"
                                />

                                <div class="container mt-2">
                                    <h6 className="text-black font-weight-normal">
                                        FAQ
                                    </h6>
                                </div>

                                <div class="">
                                    <img
                                        src={IcRightArrow}
                                        alt="rightArrow"
                                        class="float-right mt-1 mr-0"
                                    />
                                </div>
                            </div>
                        </Link> */}

                        {/* Aturan */}
                        {/* <Link to="/akun/Aturan" class="text-dark mt-2">
                            <div className="d-flex flex-row align-items-center justify-contents-between mt-2">
                                <img
                                    src={IcPaper}
                                    alt="paper"
                                    className="mr-10"
                                />
                                <div class="container mt-2">
                                    <h6 className="text-black font-weight-normal">
                                        Aturan
                                    </h6>
                                </div>

                                <div class="">
                                    <img
                                        src={IcRightArrow}
                                        alt="rightArrow"
                                        class="float-right mt-1 mr-0"
                                    />
                                </div>
                            </div>
                        </Link> */}

                        {/* <Link
                            to="/akun/KebijakanPrivasi"
                            class="text-dark mt-3"
                        >
                            <div className="d-flex flex-row align-items-center justify-contents-between mt-2">
                                <img
                                    src={IcDangerCircle}
                                    alt="dangerCircle"
                                    className="
                                    mr-10"
                                />
                                <div class="container mt-2">
                                    <h6 className="text-black font-weight-normal">
                                        Kebijakan Privasi
                                    </h6>
                                </div>

                                <div class="">
                                    <img
                                        src={IcRightArrow}
                                        alt="rightArrow"
                                        class="float-right mt-1 mr-0"
                                    />
                                </div>
                            </div>
                        </Link> */}

                        {supportsPWA && (
                            <Button
                                color="primary"
                                type="button"
                                variant="contained"
                                className="mb-2 w-100 py-2 btn-auth mt-5 text-white"
                                onClick={installApp}
                            >
                                Install App
                            </Button>
                        )}

                        <button
                            type="button"
                            style={{ paddingBlock: '8px' }}
                            className="btn btn-light btn-block border rounded-6 border-danger text-danger mt-2"
                            onClick={handleSubmit}
                        >
                            Keluar
                        </button>
                    </div>
                </div>
            </Sidebar>
        </div>
    );
};

export default AkunIndex;
