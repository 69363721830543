import { API, setAuthToken } from '../../config/Api';
export const GET_RIWAYAT_ABSENSI_KARYAWAN = 'GET_RIWAYAT_ABSENSI_KARYAWAN';
export const GET_LOKASI_ABSENSI = 'GET_LOKASI_ABSENSI';
export const GET_WAKTU_ABSENSI = 'GET_WAKTU_ABSENSI';

export const addAbsenMasuk = (params) => {
    return (dispatch, getState) => {
        // const state = getState();
        // const absenState = state.absen.absen;
        const currentTime = new Date();
        const currentHour = currentTime.getHours();
        const currentMinutes = currentTime.getMinutes();
        // const currentDate = currentTime.toDateString();
        const days = ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'];
        const hari = days[currentTime.getDay()];

        const hariAngka = currentTime.getDate();
        const tanggal = `${hariAngka} ${hari}`;

        const newAbsen = {
            waktuMasuk: `${currentHour}:${currentMinutes}`,
            waktuKeluar: '-',
            tanggal: tanggal,
            kehadiran: currentHour >= 8 ? 'Terlambat' : 'Tepat Waktu'
        };

        dispatch({
            type: 'ADD_ABSEN_MASUK',
            payload: [newAbsen]
        });
    };
};

export const addAbsenKeluar = (params) => {
    return (dispatch, getState) => {
        const state = getState();
        const absenState = state.absen.absen;
        const absenKeys = Object.keys(absenState);
        const latestKey = absenKeys[absenKeys.length - 1];
        const latestAbsen = absenState[latestKey];
        if (latestAbsen.waktuKeluar === '-') {
            let currentDate = new Date();
            let currentHour = currentDate.getHours();
            let currentMinute = currentDate.getMinutes();
            let formattedCurrentTime = `${currentHour}:${currentMinute}`;
            latestAbsen.waktuKeluar = formattedCurrentTime;
            dispatch({
                type: 'ADD_ABSEN_KELUAR',
                payload: []
            });
        }
    };
};

export const getRiwayatAbsensiKaryawan = (params) => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        // const token =
        //     'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYTRjNTYyYTdiYTkzZmU4MTEyMTAzNzE2MmI4NmIwMzJiYzk4ZjBlMjdmMzcyZjhmZDFjYTFkZjI1MGQwOTMxM2JmMzNlYTVkYWVkYWYyZWQiLCJpYXQiOjE2ODA0NzA5MjQuNTgyODc1LCJuYmYiOjE2ODA0NzA5MjQuNTgyODc3LCJleHAiOjE3MTIwOTMzMjQuNDgyODEzLCJzdWIiOiIyOCIsInNjb3BlcyI6W119.jwjzH-A08yJZZNCqzNlGyx5C2bXI1Bj54lIOs8pyHh05sVndfmyctUjuqTsjdM2GQbNAfprIft3MfNKcCVVnsrjOUozg7XS54JZ8SXUeS3U4uHn9XGoR-zaYhh36vpiRu5YLJJ7KI0QPiFCA8b1g09nEG9upO5D9i4JoqA069j16zmGDX0yauerQzakLQE4HF5HEnYMHuVrWHLEU3OWgrAOxjnuRPh1OQFz8MjhvmjUk_Sh7CdZ1xKJ-4zPZUfb3mydJrglwkHREu5uWuk0gHO8xRZpyYT4qHLOv6engJFIEUReVfutxPUZK-IRufMCNgKJdEWjAj3hS0mAeq47SPmNiYRVNSEAILwKRpMMnb5ZwUD6rZ3CWLDLXQaDZSb9msdsz0cJFckG5yAVgrwNUCNpa6nEvyYUWJrSWRwDrdj1zhvkmlaSjZ3pKoIbomJi96Ir3PHmw9qcqJeYbwTVLP4Z11UFRYtZpUq5GJOomfcBg-HNh7CDrnFFZZPCtLr3XKQcs00UcI-J2viu0C1k0e_80L0YtWikrJ2MN9q1aJZJG2R2wAS26_jo6RoYNVY0jO4JMtoAt_HYI_8M4OddHDdqJs2J0hcNefv7qPaOJ3I7lI5xPgBByll6nnNEj34qEvfMPx8AhFMCXIA0dT41kydE-4uIyf-MM5njph6VPGts';
        setAuthToken(token);
        const res = await API.get(
            `/user/absensi/absensi_karyawan_auth/kehadiran${params}`
        ).catch((err) => {
            return Promise.reject(err);
        });
        if (res.data.code === 0) {
            dispatch({
                type: GET_RIWAYAT_ABSENSI_KARYAWAN,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: GET_RIWAYAT_ABSENSI_KARYAWAN,
                payload: []
            });
        }
    };
};

export const addKaryawanAbsensi = (body) => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
        const res = await API.post(
            `/user/absensi/absensi_karyawan_auth/add`,
            body
        ).catch((err) => {
            return Promise.reject(err);
        });

        return res.data;
    };
};

export const editKaryawanAbsensi = (body) => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
        const res = await API.post(
            `/user/absensi/absensi_karyawan_auth/update`,
            body
        ).catch((err) => {
            return Promise.reject(err);
        });

        return res.data;
    };
};

export const getLokasiAbsensi = () => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        const res = await API.get(
            `/user/absensi/absensi_karyawan_auth/lokasi_absensi`
        ).catch((err) => {
            return Promise.reject(err);
        });
        if (res.data.code === 0) {
            dispatch({
                type: GET_LOKASI_ABSENSI,
                payload: res.data.data
            });
        }
    };
};

export const getWaktuAbsensi = () => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        const res = await API.get(
            `/user/absensi/absensi_karyawan_auth/waktu_absensi`
        ).catch((err) => {
            return Promise.reject(err);
        });
        if (res.data.code === 0) {
            dispatch({
                type: GET_WAKTU_ABSENSI,
                payload: res.data.data
            });
        }
    };
};
