import { API, setAuthToken } from '../../config/Api';
import { GET_DAFTAR_IZIN_CUTI_DASHBOARD, GET_DAFTAR_TERLAMBAT_DASHBOARD, GET_DATA_DASHBOARD, GET_GAJI_DASHBOARD, GET_IZIN_CUTI_DASHBOARD } from '../constant';

export const getDataDashboard = (params) => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        const res = await API.get(
            `/user/hr_mobile/dashboard/data` + params
        ).catch((err) => {
            return Promise.reject(err);
        });
        if (res.data.code === 0) {
            dispatch({
                type: GET_DATA_DASHBOARD,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: GET_DATA_DASHBOARD,
                payload: []
            });
        }
    };
};

export const getIzinCutiDashboard = (params) => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        const res = await API.get(
            `/user/hr_mobile/dashboard/izin_cuti` + params
        ).catch((err) => {
            return Promise.reject(err);
        });
        if (res.data.code === 0) {
            dispatch({
                type: GET_IZIN_CUTI_DASHBOARD,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: GET_IZIN_CUTI_DASHBOARD,
                payload: []
            });
        }
    };
};

export const getGajiDashboard = (params) => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        const res = await API.get(
            `/user/hr_mobile/dashboard/gaji` + params
        ).catch((err) => {
            return Promise.reject(err);
        });
        if (res.data.code === 0) {
            dispatch({
                type: GET_GAJI_DASHBOARD,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: GET_GAJI_DASHBOARD,
                payload: []
            });
        }
    };
};

export const getDaftarTerlambatDashboard = (params) => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        const res = await API.get(
            `/user/hr_mobile/dashboard/daftar_terlambat` + params
        ).catch((err) => {
            return Promise.reject(err);
        });
        if (res.data.code === 0) {
            dispatch({
                type: GET_DAFTAR_TERLAMBAT_DASHBOARD,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: GET_DAFTAR_TERLAMBAT_DASHBOARD,
                payload: []
            });
        }
    };
}

export const getDaftarIzinCutiDashboard = (params) => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        const res = await API.get(
            `/user/hr_mobile/dashboard/daftar_izin_cuti` + params
        ).catch((err) => {
            return Promise.reject(err);
        });
        if (res.data.code === 0) {
            dispatch({
                type: GET_DAFTAR_IZIN_CUTI_DASHBOARD,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: GET_DAFTAR_IZIN_CUTI_DASHBOARD,
                payload: []
            });
        }
    };
}