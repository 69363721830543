import { Button, Chip, IconButton, Tooltip } from '@material-ui/core';
import {
    CheckCircleOutlineOutlined,
    HighlightOffRounded
} from '@material-ui/icons';
import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatDateName } from '../../utils/formatDate';
import { formatDateFns } from '../../utils/formatDateFns';
import { IcFingerClick } from '../../assets';

function formatTime(inputStr) {
    if (inputStr === undefined || inputStr === null) return '';

    return inputStr.slice(0, 5);
}

function isInputToday(tanggal) {
    var today = new Date();
    var inputDate = new Date(tanggal);

    if (
        inputDate.getDate() === today.getDate() &&
        inputDate.getMonth() === today.getMonth() &&
        inputDate.getFullYear() === today.getFullYear()
    ) {
        return true;
    } else {
        return false;
    }
}

const CardMessages = ({
    isRead,
    name,
    message,
    time,
    username,
    onClick,
    notif,
    picture,
    to,
    isNotif,
    isMember,
    statusMember,
    handleStatus,
    data,
    isMe,
    submitFriend,
    canChat,
    isRecentMatch,
    isAbsent,
    waktuMasuk,
    waktuKeluar,
    tanggal,
    kehadiran,
    code,
    onClickCard,
    lembur
}) => {
    const isInput = isInputToday(tanggal);
    console.log('isInput', isInput);
    console.log('kehadiran', kehadiran);
    return (
        <>
            {isMember ? (
                <Card className="py-2 d-flex flex-row align-items-center border-0 radius-8 card-chat">
                    <img
                        src={picture}
                        alt={name}
                        className="profile rounded-circle"
                    />
                    <div className="w-100 content ml-3 d-flex flex-row align-items-center justify-content-between flex-1">
                        <div>
                            <Link
                                // to={`/profile/${data.username}`}
                                className="link-hover"
                            >
                                <h6 className="nama">{name}</h6>
                            </Link>
                            <p className="message elipse-2 mb-1">{message}</p>
                            <div className="d-flex align-item-center">
                                {username && (
                                    <p className="m-0 font-weight-light text-dark mr-3">
                                        {username}
                                    </p>
                                )}
                                {statusMember === 2 ? (
                                    <Chip
                                        label="pending"
                                        color="secondary"
                                        size="small"
                                        className="text-black"
                                    />
                                ) : null}
                            </div>
                        </div>
                        <div>
                            {!isMe && statusMember === 2 && canChat ? (
                                <div className="d-flex align-items-center">
                                    <Tooltip title="reject user">
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                handleStatus(
                                                    data.member_code,
                                                    data.group_code,
                                                    data.users_code,
                                                    data.fullname,
                                                    0
                                                )
                                            }
                                        >
                                            <HighlightOffRounded color="disabled" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="accept user">
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                handleStatus(
                                                    data.member_code,
                                                    data.group_code,
                                                    data.users_code,
                                                    data.fullname,
                                                    1
                                                )
                                            }
                                        >
                                            <CheckCircleOutlineOutlined color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            ) : !isMe && data.matched_status === 0 ? (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className="rounded-8"
                                    size="small"
                                    onClick={() =>
                                        onClick(
                                            data,
                                            data.matched_status === 0 ? 1 : 0
                                        )
                                    }
                                    disabled={submitFriend}
                                >
                                    Add Buddy
                                </Button>
                            ) : !isMe && data.matched_status === 2 ? (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className="rounded-8"
                                    size="small"
                                    onClick={() =>
                                        onClick(
                                            data,
                                            data.matched_status === 0 ? 1 : 0
                                        )
                                    }
                                    disabled={submitFriend}
                                >
                                    Cancel
                                </Button>
                            ) : null}
                        </div>
                    </div>
                </Card>
            ) : isAbsent ? (
                <Card className="  bg-light-grey px-4 py-2 d-flex flex-row align-items-center radius-6 mxw-90 no-border  ">
                    <div
                        className={
                            kehadiran === 'hadir' || kehadiran === 'Hadir'
                                ? 'absent-card-date bg-green-box'
                                : kehadiran === 'terlambat' || kehadiran === 'Terlambat'
                                ? 'absent-card-date bg-yellow-box '
                                : kehadiran === 'cuti' || kehadiran === 'izin'
                                ? 'absent-card-date bg-grey-border'
                                : 'absent-card-date bg-red'
                        }
                    >
                        <h1>{formatDateName(tanggal)}</h1>
                    </div>
                    <div className="content ml-3 d-flex flex-row align-items-center justify-contents-between  flex-1 absent-card-time w-90vw">
                        {isInput &&
                        !waktuMasuk &&
                        kehadiran !== 'izin' &&
                        kehadiran !== 'cuti' ? (
                            <div className="absen-input-container">
                                <Link
                                    to={
                                        lembur
                                            ? '/InputAbsensi/Masuk_Lembur'
                                            : '/InputAbsensi/Masuk'
                                    }
                                    className="absen-input-btn"
                                >
                                    <img src={IcFingerClick} alt="finger" />
                                    Masuk
                                </Link>
                            </div>
                        ) : (
                            <div
                                className={
                                    kehadiran === 'hadir' || kehadiran === 'Hadir'
                                        ? 'flex-1 text-h1-green-box'
                                        : kehadiran === 'terlambat' || kehadiran === 'Terlambat'
                                        ? 'flex-1 text-h1-yellow-box'
                                        : kehadiran === 'izin' ||
                                          kehadiran === 'cuti'
                                        ? 'flex-1 text-black '
                                        : 'flex-1'
                                }
                            >
                                <h2>Masuk</h2>
                                <h1>
                                    {waktuMasuk ? formatTime(waktuMasuk) : '-'}
                                </h1>
                            </div>
                        )}
                        <div className="w-1px  h-50px  bg-grey-border br-2  "></div>

                        {isInput &&
                        !waktuKeluar &&
                        kehadiran !== 'izin' &&
                        kehadiran !== 'cuti' ? (
                            <div className="absen-input-container">
                                {waktuMasuk ? (
                                    <Link
                                        to={{
                                            pathname: lembur
                                                ? `/InputAbsensi/Keluar_Lembur/${code}`
                                                : `/InputAbsensi/Keluar/${code}`,
                                            state: { waktuMasuk }
                                        }}
                                    >
                                        <div className="absen-input-btn">
                                            <img
                                                src={IcFingerClick}
                                                alt="finger"
                                            />
                                            Keluar
                                        </div>
                                    </Link>
                                ) : (
                                    <div className="absen-input-btn-inactive">
                                        <img
                                            src={IcFingerClick}
                                            alt="finger"
                                            className="keluar-click"
                                        />
                                        Keluar
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div
                                className={
                                    kehadiran === 'hadir' || kehadiran === 'Hadir'
                                        ? 'flex-1 text-h1-green-box'
                                        : kehadiran === 'terlambat' || kehadiran === 'Terlambat'
                                        ? 'flex-1 text-h1-yellow-box'
                                        : kehadiran === 'izin' ||
                                          kehadiran === 'cuti'
                                        ? 'flex-1 text-black '
                                        : 'flex-1'
                                }
                            >
                                <h2>Keluar</h2>
                                <h1>
                                    {waktuKeluar
                                        ? formatTime(waktuKeluar)
                                        : '-'}
                                </h1>
                            </div>
                        )}
                    </div>
                </Card>
            ) : (
                <Link to={to} className={`text-decoration-none `}>
                    <Card
                        className={` px-2 py-3 d-flex flex-row  border-0 radius-8 card-chat ${
                            isRead ? 'color-white background-primary' : ''
                        }`}
                        onClick={onClickCard}
                    >
                        <div
                            className={`profile text-muted ${
                                isRead ? 'background-white' : ''
                            }`}
                        >
                            <img src={picture} alt={name} />
                        </div>

                        <div
                            className={`w-100 content ml-3 d-flex    flex-1 ${
                                isRead ? 'color-white' : ''
                            }`}
                        >
                            <div className="flex-1 mr-2">
                                <p className="nama">{name}</p>
                                <p className="message elipse-2">{message}</p>
                                {username && (
                                    <p className="m-0 font-weight-light">
                                        {username}
                                    </p>
                                )}
                            </div>
                            <div>
                                <p className="waktu">{formatDateFns(time, 'EEEE, d MMMM yyyy HH:ii')}</p>
                                {isNotif && (
                                    <span className="badge badge-custom">
                                        {notif}
                                    </span>
                                )}
                            </div>
                        </div>
                    </Card>
                </Link>
            )}
        </>
    );
};

export default CardMessages;
