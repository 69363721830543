import { InputAdornment, TextField } from '@material-ui/core';
import { IcWhiteSearch } from '../../assets';

const SearchKaryawan = ({ search, setSearch }) => {
    return (
        <TextField
            value={search}
            type="text"
            className="input-search-name"
            placeholder="Cari Nama Karyawan"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <img src={IcWhiteSearch} alt="" />
                    </InputAdornment>
                )
            }}
            onChange={(e) => {
                setSearch(e.target.value);
            }}
        />
    );
};

export default SearchKaryawan;
