import { API, setAuthToken } from '../../config/Api';
import {
    CHECK_TOKEN_RESET,
    LOGIN,
    LOGOUT,
    RESET_PASSWORD,
    SEND_FORGOT,
    VERIFY_EMAIL
} from '../constant';

export const login = (params) => {
    return {
        type: LOGIN,
        payload: async () => {
            const res = await API.post('hr_mobile/login', params).catch((error) =>
                Promise.reject(error)
            );
            if (res.data.code === 0) {
                localStorage.setItem('token', res.data.data.api_token);
                localStorage.setItem('unit_kerja', res.data.data.unit_kerja);

                localStorage.setItem('email', res.data.data.email);
                localStorage.setItem('users_code', res.data.data.users_code);
                localStorage.setItem('username', res.data.data.name);
                const result = {
                    authenticated: true,
                    user: res.data.data
                };
                return result;
            }
            return res.data;
        }
    };
};

export const sendForgot = (params) => {
    return {
        type: SEND_FORGOT,
        payload: async () => {
            const res = await API.post('forgot_password', params).catch(
                (error) => Promise.reject(error)
            );
            return res.data;
        }
    };
};

export const resetPassword = (params) => {
    return {
        type: RESET_PASSWORD,
        payload: async () => {
            const res = await API.post('forgot_password/next', params).catch(
                (error) => Promise.reject(error)
            );
            return res.data;
        }
    };
};

export const logout = () => {
    return {
        type: LOGOUT,
        payload: async () => {
            const token = await localStorage.getItem('token');
            setAuthToken(token);
            localStorage.clear();
            const res = await API.post('user/logout').catch((error) =>
                Promise.reject(error)
            );

            if (res.data.code === 0) {
                let remember = localStorage.getItem('rememberMe');
                let emailUser, psUser;
                if (remember === 'true') {
                    emailUser = localStorage.getItem('emailUser');
                    psUser = localStorage.getItem('psUser');
                }
                localStorage.clear();
                localStorage.setItem('rememberMe', remember);
                if (remember === 'true') {
                    localStorage.setItem('emailUser', emailUser);
                    localStorage.setItem('psUser', psUser);
                }
            }
            return res.data;
        }
    };
};

export const sendVerifyEmail = (id, hash, expires) => {
    return {
        type: VERIFY_EMAIL,
        payload: async () => {
            const res = await API.get(
                `email/verify/${id}/${hash}/${expires}`
            ).catch((error) => Promise.reject(error));
            return res.data;
        }
    };
};

export const checkTokenReset = (params) => {
    return {
        type: CHECK_TOKEN_RESET,
        payload: async () => {
            const res = await API.post('check_token/reset', params).catch(
                (error) => Promise.reject(error)
            );
            return res.data;
        }
    };
};
