import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import reducer from './reducers';
import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import promise from 'redux-promise-middleware';
const history = createBrowserHistory();
const persistConfig = {
    key: 'root',
    storage
};

persistReducer(persistConfig, reducer);
const middleware = applyMiddleware(promise, thunk, routerMiddleware(history));

const store = createStore(reducer, middleware);
let persist = persistStore(store);
export { store, persist };
