import { API, setAuthToken } from '../../config/Api';
import axios from 'axios';
export const GET_NOTIFIKASI = 'GET_NOTIFIKASI';
export const GET_PERINGATAN = 'GET_PERINGATAN';

export const getNotifikasi = () => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        const res = await API.get(`/user/notifikasi`).catch((err) => {
            return Promise.reject(err);
        });
        if (res.data.code === 0) {
            dispatch({
                type: GET_NOTIFIKASI,
                payload: res.data.data.data
            });
        } else {
            dispatch({
                type: GET_NOTIFIKASI,
                payload: []
            });
        }
    };
};
export const editNotifikasi = (code) => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
        const res = await API.patch(`/user/notifikasi/${code}`).catch(
            (err) => {
                return Promise.reject(err);
            }
        );

        return res.data;
    };
};

export const clearNotifikasi = (code) => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
        const res = await API.patch(`/user/notifikasi`).catch(
            (err) => {
                return Promise.reject(err);
            }
        );

        return res.data;
    };
};



const APIRemote = axios.create({
    baseURL: `https://api.office.alan.co.id/api`
});

export const getSuratPeringatan = (code) => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        const res = await APIRemote.get(
            `/remote/surat_pelanggaran/check/${code}`
        ).catch((err) => {
            return Promise.reject(err);
        });
        if (res.data.code === 0) {
            dispatch({
                type: GET_PERINGATAN,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: GET_PERINGATAN,
                payload: []
            });
        }
    };
};
