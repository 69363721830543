import '../../assets/style/homepage.css';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import bg_pattern from '../../assets/images/bg_pattern.png';
import { Sidebar, ModalAlasanDitolak, MonthYearPicker } from '../../components';
import { getNotifikasi } from '../../store/actions/notifikasiAction';
import {
    getGajiDashboard
} from '../../store/actions/dashboardAction';
import { formatDateFns } from '../../utils/formatDateFns';
import Swal from 'sweetalert2';
import { Box, Tab, Tabs } from '@material-ui/core';
import useDateFilter from '../../hooks/useDateFilter';
import { formatRupiah } from '../../utils/formatRupiah';
import { editStatusLembur, editStatusReimburse } from '../../store/actions/gajiAction';
import { IcBell } from '../../assets';
import { getQueryParameter } from '../../utils/getQueryParameter';

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CustomTabPanel = ({ children, value, index, ...other }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

const Gaji = ({
    dataNotifikasi,
    dataGajiDashboard,
    getNotifikasi,
    getGajiDashboard,
    editStatusReimburse,
    editStatusLembur
}) => {
    const notifCount = dataNotifikasi.filter(
        (item) => item.status === '1'
    ).length;
    const [date, setDate] = useDateFilter();
    const [openDitolak, setOpenDitolak] = useState(false);
    const [alasan, setAlasan] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const tipe = getQueryParameter('tipe');
    const [currentTab, setCurrentTab] = useState(() => {
        if (tipe) {
            switch (tipe) {
                case 'lembur':
                    return 0;
                case 'reimburse':
                    return 1;
                default:
                    return 0;
            }
        }

        return 0;
    });

    const currentTabLabel = {
        0: 'lembur',
        1: 'reimburse'
    };

    useEffect(() => {
        getNotifikasi();
        document.title = 'AlanHR | Gaji (Lembur & Reimburse)';
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getDataGajiDashboard();
        // eslint-disable-next-line
    }, [date.getMonth(), date.getFullYear(), currentTab]);

    const getDataGajiDashboard = () => {
        const params = `?bulan_tahun=${formatDateFns(date, 'MM-yyyy')}&tipe=${currentTabLabel[currentTab]}`;
        getGajiDashboard(params);
    };

    const handleUpdateGajiStatus = (item, status) => {
        const type = item.type;

        switch (type) {
            case 'lembur':
                if (status === 'ditolak' && !item.keterangan) {
                    item.keterangan = 'tidak ada keterangan';
                }
                editStatusLembur({ ...item, status })
                    .then((res) => {
                        Swal.fire({
                            title: 'Success',
                            text: 'Data Lembur Berhasil Disimpan',
                            icon: 'success',
                            iconColor: '#00ACEE',
                            timer: 2000,
                            showConfirmButton: false
                        });

                        getDataGajiDashboard();
                    })
                    .catch((err) => {
                        Swal.fire({
                            title: 'Error',
                            text: err.message,
                            icon: 'error',
                            timer: 2000,
                            showConfirmButton: false
                        });
                    });
                break;
            case 'reimburse':
                editStatusReimburse({ ...item, status, alasan })
                    .then((res) => {
                        Swal.fire({
                            title: 'Success',
                            text: 'Data Reimburse Berhasil Disimpan',
                            icon: 'success',
                            iconColor: '#00ACEE',
                            timer: 2000,
                            showConfirmButton: false
                        });
                        getDataGajiDashboard();
                    })
                    .catch((err) => {
                        Swal.fire({
                            title: 'Error',
                            text: err.message,
                            icon: 'error',
                            timer: 2000,
                            showConfirmButton: false
                        });
                    });
                break;
            default:
                break;
        }

        setOpenDitolak(false);
    };

    const handleOpenDitolak = (item) => {
        setSelectedItem(item);
        setOpenDitolak(true);
    };

    const handleCloseDitolak = () => {
        setOpenDitolak(false);
    };

    const handleAlasanDitolak = (e) => {
        setAlasan(e.target.value);
    };

    const changeTab = (e, newTab) => {
        setCurrentTab(newTab);
    }

    return (
        <div className="wrapper">
            <Sidebar isHome={true}>
                <div className="home-user-info-container">
                    <div className="d-flex justify-content-between">
                        <div className="home-user-info">
                            <h1>Gaji (Lembur & Reimburse)</h1>
                        </div>
                        <Link to="/notifikasi" className="notification-icon">
                            <div className="notification-count">
                                {notifCount}
                            </div>
                            <img src={IcBell} alt="bell" />
                        </Link>
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-center container-home  ">
                    <img
                        src={bg_pattern}
                        alt="bg_pattern"
                        className="bg-pattern"
                    />

                    <div className="home-card-wrapper">
                        <MonthYearPicker date={date} setDate={setDate} />

                        <div className="izin-cuti-container">
                            <Tabs
                                value={currentTab}
                                onChange={changeTab}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                style={{ marginBottom: "20px" }}
                                size="small"
                            >
                                <Tab onClick={() => { }} label="Lembur" {...a11yProps(0)} />
                                <Tab onClick={() => { }} label="Reimburse" {...a11yProps(1)} />
                            </Tabs>

                            <CustomTabPanel value={currentTab} index={0} style={{ marginBottom: '100px' }}>
                                {dataGajiDashboard.length > 0 ? (
                                    <div className="izin-cuti-data-container">
                                        {dataGajiDashboard.map(
                                            (item, index) => (
                                                <div className="izin-cuti-data" key={index}>
                                                    <div className="izin-cuti-data-content">
                                                        <p className="izin-cuti-data-nama">
                                                            {item.name}
                                                        </p>
                                                        <p title={item.keterangan}>{item.keterangan}</p>
                                                        <p>{item.upah_lembur !== 0 ? formatRupiah(item.upah_lembur) : ''} ({item.total_jam} jam {item.jenis_hari})</p>
                                                        <p>
                                                            <span className="izin-cuti-data-tanggal">
                                                                {formatDateFns(
                                                                    item.tanggal,
                                                                    'd MMMM yyyy'
                                                                )}
                                                                {' '}{item.waktu_mulai?.replace(/:\d\d$/, '')} - {item.waktu_akhir?.replace(/:\d\d$/, '')}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="izin-cuti-data-action">
                                                        <button
                                                            className="izin-cuti-data-izin"
                                                            onClick={(e) =>
                                                                handleUpdateGajiStatus(
                                                                    item,
                                                                    'diterima'
                                                                )
                                                            }
                                                        >
                                                            Terima
                                                        </button>
                                                        <button
                                                            className="izin-cuti-data-tolak"
                                                            onClick={(e) =>
                                                                handleUpdateGajiStatus(
                                                                    item,
                                                                    'ditolak'
                                                                )
                                                            }
                                                        >
                                                            Tolak
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                ) : (
                                    <p className="pesan-no-data">
                                        Tidak ada pengajuan lembur yang
                                        ditujukan untuk bulan ini
                                    </p>
                                )}
                            </CustomTabPanel>
                            <CustomTabPanel value={currentTab} index={1} style={{ marginBottom: '100px' }}>
                                {dataGajiDashboard.length > 0 ? (
                                    <div className="izin-cuti-data-container">
                                        {dataGajiDashboard.map(
                                            (item, index) => (
                                                <div className="izin-cuti-data" key={index}>
                                                    <div className="izin-cuti-data-content">
                                                        <p className="izin-cuti-data-nama">
                                                            {item.name}
                                                        </p>
                                                        <p title={item.keterangan}>{item.keterangan}</p>
                                                        <p>{formatRupiah(item.nominal)}</p>
                                                        <p>
                                                            <span className="izin-cuti-data-tanggal">
                                                                {formatDateFns(
                                                                    item.tanggal,
                                                                    'd MMMM yyyy'
                                                                )}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="izin-cuti-data-action">
                                                        <button
                                                            className="izin-cuti-data-izin"
                                                            onClick={(e) =>
                                                                handleUpdateGajiStatus(
                                                                    item,
                                                                    'diterima'
                                                                )
                                                            }
                                                        >
                                                            Terima
                                                        </button>
                                                        <button
                                                            className="izin-cuti-data-tolak"
                                                            onClick={(e) =>
                                                                handleOpenDitolak(
                                                                    item
                                                                )
                                                            }
                                                        >
                                                            Tolak
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                ) : (
                                    <p className="pesan-no-data">
                                        Tidak ada pengajuan reimburse yang
                                        ditujukan untuk bulan ini
                                    </p>
                                )}
                            </CustomTabPanel>
                        </div>
                    </div>
                </div>
            </Sidebar>
            {selectedItem &&
                <ModalAlasanDitolak
                    alasan={alasan}
                    handleAlasanDitolak={handleAlasanDitolak}
                    handleCloseDitolak={handleCloseDitolak}
                    handleUpdateStatus={handleUpdateGajiStatus}
                    openDitolak={openDitolak}
                    selectedItem={selectedItem}
                />
            }
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        dataNotifikasi: state.notifikasi.dataNotifikasi,
        dataGajiDashboard: state.dashboard.dataGajiDashboard
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getNotifikasi: () => dispatch(getNotifikasi()),
        getGajiDashboard: (params) =>
            dispatch(getGajiDashboard(params)),
        editStatusReimburse: (params) => dispatch(editStatusReimburse(params)),
        editStatusLembur: (params) => dispatch(editStatusLembur(params))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Gaji);
