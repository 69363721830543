import { API, setAuthToken } from '../../config/Api';
import { GET_LIST_GAJI } from '../constant';

export const getListGaji = (params) => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        const res = await API.get('user/gaji/user' + params).catch((err) =>
            Promise.reject(err)
        );

        if (res.data.code === 0) {
            dispatch({
                type: GET_LIST_GAJI,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: GET_LIST_GAJI,
                payload: []
            });
        }
    };
};

export const getDetailGaji = (params) => {
    const token = localStorage.getItem('token');
    setAuthToken(token);
    return API.post('user/gaji/user/' + params + '/cetak');
};

export const editStatusLembur = (params) => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
        const res = await API.post('/user/gaji/lembur/update/status', params).catch(
            (err) => {
                return Promise.reject(err);
            }
        );
        return res.data;
    }
}

export const editStatusReimburse = (params) => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
        const res = await API.post('/user/gaji/reimburse/updateStatus', params).catch(
            (err) => {
                return Promise.reject(err);
            }
        );
        return res.data;
    }
}

