import { createBrowserHistory } from 'history';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoutes from '../utils/PrivateRoutes';

import {
    Login,
    Forgot,
    Home,
    IzinCuti,
    Gaji,
    Notifikasi,
    AkunIndex,
    UbahProfile,
    UbahKataSandi,
    NotFound,
    DaftarTerlambat,
    DaftarIzinCuti,
} from '../pages';

export const history = createBrowserHistory();

const Routes = () => {
    return (
        <Switch>
            <PrivateRoutes.UserLogin exact path="/" component={Home} />
            <PrivateRoutes.UserNotLogin path="/login" component={Login} />
            <PrivateRoutes.UserLogin exact path="/forgot_password" component={Forgot} />
            <PrivateRoutes.UserLogin path="/daftar_terlambat" component={DaftarTerlambat} />
            <PrivateRoutes.UserLogin path="/daftar_izin_cuti" component={DaftarIzinCuti} />
            <PrivateRoutes.UserLogin path="/izin_cuti" component={IzinCuti} />
            <PrivateRoutes.UserLogin path="/gaji" component={Gaji} />
            <PrivateRoutes.UserLogin path="/notifikasi" component={Notifikasi} />
            <PrivateRoutes.UserLogin exact path="/akun" component={AkunIndex} />
            <PrivateRoutes.UserLogin exact path="/akun/ubahProfile" component={UbahProfile} />
            <PrivateRoutes.UserLogin exact path="/akun/ubahKataSandi" component={UbahKataSandi} />
            <Route component={NotFound} />
        </Switch>
    );
};

export default Routes;
