import { GET_DAFTAR_IZIN_CUTI_DASHBOARD, GET_DAFTAR_TERLAMBAT_DASHBOARD, GET_DATA_DASHBOARD, GET_GAJI_DASHBOARD, GET_IZIN_CUTI_DASHBOARD } from "../constant";

const initialState = {
    dataDashboard: {},
    dataDaftarTerlambatDashboard: {},
    dataDaftarIzinCutiDashboard: {},
    dataIzinCutiDashboard: [],
    dataGajiDashboard: [],
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DATA_DASHBOARD: {
            return {
                ...state,
                dataDashboard: action.payload
            };
        }
        case GET_DAFTAR_TERLAMBAT_DASHBOARD: {
            return {
                ...state,
                dataDaftarTerlambatDashboard: action.payload
            };
        }
        case GET_DAFTAR_IZIN_CUTI_DASHBOARD: {
            return {
                ...state,
                dataDaftarIzinCutiDashboard: action.payload
            };
        }
        case GET_IZIN_CUTI_DASHBOARD: {
            return {
                ...state,
                dataIzinCutiDashboard: action.payload
            };
        }
        case GET_GAJI_DASHBOARD: {
            return {
                ...state,
                dataGajiDashboard: action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default dashboardReducer;
