import { format } from 'date-fns';
import { id } from 'date-fns/locale';

export const formatDateFns = (date, str) => {
    try {
        return format(new Date(date), str, { locale: id });
    } catch (e) {
        return '';
    }
};

