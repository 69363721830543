import '../../assets/style/homepage.css';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IcBell } from '../../assets/icons';
import bg_pattern from '../../assets/images/bg_pattern.png';
import { Sidebar, MonthYearPicker, SearchKaryawan } from '../../components';
import { getNotifikasi } from '../../store/actions/notifikasiAction';
import { getDaftarIzinCutiDashboard } from '../../store/actions/dashboardAction';
import { formatDateFns } from '../../utils/formatDateFns';
import useDateFilter from '../../hooks/useDateFilter';

const DaftarIzinCuti = ({
    dataNotifikasi,
    dataDaftarIzinCutiDashboard,
    getNotifikasi,
    getDaftarIzinCutiDashboard
}) => {
    const notifCount = dataNotifikasi.filter(
        (item) => item.status === 'dikirim'
    ).length;

    const [date, setDate] = useDateFilter();
    const [search, setSearch] = useState('');

    useEffect(() => {
        getNotifikasi();
        document.title = 'AlanHR | Daftar Izin & Cuti';
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getDataDaftarIzinCutiDashboard();
        // eslint-disable-next-line
    }, [date.getMonth(), date.getFullYear()]);

    useEffect(() => {
        setSearch('');
    }, [date]);

    const getDataDaftarIzinCutiDashboard = () => {
        const params = `?bulan_tahun=${formatDateFns(date, 'MM-yyyy')}`;
        getDaftarIzinCutiDashboard(params);
    };

    return (
        <div className="wrapper">
            <Sidebar isHome={true}>
                <div className="home-user-info-container">
                    <div className="d-flex justify-content-between">
                        <div className="home-user-info">
                            <h1>Izin & Cuti</h1>
                        </div>
                        <Link to="/notifikasi" className="notification-icon">
                            <div className="notification-count">
                                {notifCount}
                            </div>
                            <img src={IcBell} alt="bell" />
                        </Link>
                    </div>
                    <div className="home-user-location">
                        <SearchKaryawan search={search} setSearch={setSearch} />
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-center container-home  ">
                    <img
                        src={bg_pattern}
                        alt="bg_pattern"
                        className="bg-pattern"
                    />

                    <div className="home-card-wrapper">
                        <MonthYearPicker date={date} setDate={setDate} />

                        <div className="izin-cuti-container">
                            {Object.entries(dataDaftarIzinCutiDashboard)
                                .length > 0 ? (
                                <div
                                    className="izin-cuti-data-container"
                                    style={{ marginBottom: '100px' }}
                                >
                                    {Object.entries(
                                        dataDaftarIzinCutiDashboard
                                    ).map(([key, daftarIzinCuti], index) => {
                                        const shownData = daftarIzinCuti.filter(
                                            (item) =>
                                                item.name
                                                    .toLowerCase()
                                                    .includes(
                                                        search.toLowerCase()
                                                    )
                                        );
                                        return shownData.length > 0 ? (
                                            <React.Fragment key={index}>
                                                <p>
                                                    {formatDateFns(
                                                        key,
                                                        'd MMMM yyyy'
                                                    )}
                                                </p>
                                                {shownData.map(
                                                    (item, index2) => (
                                                        <div
                                                            className="izin-cuti-data"
                                                            style={{
                                                                marginBottom:
                                                                    '20px'
                                                            }}
                                                            key={index2}
                                                        >
                                                            {console.log(
                                                                'index',
                                                                index,
                                                                [key, item]
                                                            )}
                                                            <div className="izin-cuti-data-content">
                                                                <p className="izin-cuti-data-nama">
                                                                    {item.name}
                                                                </p>
                                                                <p
                                                                    title={
                                                                        item.keterangan
                                                                    }
                                                                >
                                                                    {
                                                                        item.keterangan
                                                                    }
                                                                </p>
                                                                <p>
                                                                    <span className="izin-cuti-data-tanggal">
                                                                        {formatDateFns(
                                                                            item.tanggal_mulai,
                                                                            'd MMMM yyyy'
                                                                        )}
                                                                        {item.tanggal_mulai !==
                                                                        item.tanggal_akhir
                                                                            ? ` - ${formatDateFns(
                                                                                  item.tanggal_akhir,
                                                                                  'd MMMM yyyy'
                                                                              )}`
                                                                            : ''}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                            <div className="izin-cuti-data-action">
                                                                <p className="filter-badge">
                                                                    {item.type}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </React.Fragment>
                                        ) : null;
                                    })}
                                    {Object.entries(
                                        dataDaftarIzinCutiDashboard
                                    ).every(
                                        ([key, daftarIzinCuti]) =>
                                            daftarIzinCuti.filter((item) =>
                                                item.name
                                                    .toLowerCase()
                                                    .includes(
                                                        search.toLowerCase()
                                                    )
                                            ).length === 0
                                    ) && (
                                        <p className="pesan-no-data">
                                            Tidak ada karyawan dengan nama
                                            seperti "{search}" yang izin atau
                                            cuti pada bulan ini
                                        </p>
                                    )}
                                </div>
                            ) : (
                                <p className="pesan-no-data">
                                    Tidak ada karyawan
                                    {search &&
                                        ` dengan keyword nama ${search}`}{' '}
                                    yang izin atau cuti pada bulan ini
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </Sidebar>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        dataNotifikasi: state.notifikasi.dataNotifikasi,
        dataDaftarIzinCutiDashboard: state.dashboard.dataDaftarIzinCutiDashboard
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getNotifikasi: () => dispatch(getNotifikasi()),
        getDaftarIzinCutiDashboard: (params) =>
            dispatch(getDaftarIzinCutiDashboard(params))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DaftarIzinCuti);
