import { Avatar } from '@material-ui/core';
import React, { useState } from 'react';
import { useRef } from 'react';
import { Card } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { IcCamera } from '../../assets/icons';
import '../../assets/style/ubahProfile.css';
import { ModalCrop } from '../../components';

const FotoProfileHandler = ({
    uploadFoto,
    maxSize,
    preview,
    aspectRatio,
    formatIcon,
    CardStyle,
    setState
}) => {
    const [, setFileName] = useState(null);
    const [FilePath, setFilePath] = useState(null);
    let fileInput = useRef();

    const getExtension = (filename) => {
        var parts = filename.split('.');
        return parts[parts.length - 1];
    };

    const checkImage = (filename) => {
        var ext = getExtension(filename);
        if (formatIcon) {
            switch (ext.toLowerCase()) {
                case 'svg':
                case 'webp':
                    return true;
                default:
                    return false;
            }
        } else {
            switch (ext.toLowerCase()) {
                case 'jpg':
                case 'png':
                case 'webp':
                case 'jpeg':
                    return true;
                default:
                    return false;
            }
        }
    };

    const kbConverter = (size) => {
        return size * 1024;
    };

    const handleImageChange = (e) => {
        let files = e.target.files || e.dataTransfer.files;

        if (!files.length) return;
        if (checkImage(e.target.files[0].name)) {
            let file = files[0];
            let reader = new FileReader();
            if (maxSize && kbConverter(maxSize) < file.size) {
                fileInput.current.value = '';
                setFileName('');
                uploadFoto('', '');
                Swal.fire({
                    title: 'Oopss!',
                    text: 'Ukuran file terlalu besar',
                    imageUrl: '/assets/images/icon/ic_error.svg',
                    imageWidth: 92,
                    imageHeight: 92,
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#0083E2'
                });
                return;
            }
            reader.readAsDataURL(file);
            const initFile = URL.createObjectURL(file);
            setFileName(file.name);
            reader.onloadend = () => {
                uploadFoto(reader.result, initFile);
            };
        } else {
            Swal.fire({
                title: 'Oopss!',
                text: 'Format file tidak didukung',
                imageUrl: '/assets/images/icon/ic_error.svg',
                imageWidth: 92,
                imageHeight: 92,
                confirmButtonText: 'Ok',
                confirmButtonColor: '#0083E2'
            });
        }
    };

    const handleClickInput = (e) => {
        fileInput.current.click();
    };

    // const handleDeleteImage = (e) => {
    //     setState((prev) => ({
    //         ...prev,
    //         foto_profile: '',
    //         img_profile_preview: ''
    //     }));
    // };

    return (
        <div className="d-flex items-center">
            <Card style={CardStyle} className="position-relative shadow-none">
                {preview && (
                    <div className="h-full w-full position-absolute">
                        <Avatar
                            src={preview}
                            alt="preview foto"
                            variant="rounded"
                            className=""
                            style={{
                                width: '100%',
                                height: '82px',
                                borderRadius: '100%'
                            }}
                        />
                    </div>
                )}
                <input
                    type="file"
                    name="foto_profile"
                    className="cursor-pointer"
                    onChange={handleImageChange}
                    ref={fileInput}
                    style={{ display: 'none' }}
                    accept={
                        formatIcon
                            ? 'image/svg+xml, image/webp'
                            : 'image/webp, image/jpg, image/jpeg, image/png'
                    }
                    id="input-file"
                />
            </Card>
            {FilePath?.length > 0 ? (
                <ModalCrop
                    handleClose={() => {
                        setFilePath('');
                        fileInput.current.value = '';
                    }}
                    open={true}
                    filePath={FilePath}
                    handleSave={(data) => {
                        uploadFoto(data, data);
                        setFilePath(null);
                    }}
                    handleUpload={() => fileInput.current?.click()}
                    aspectRatio={aspectRatio || 1 / 1}
                />
            ) : null}
            <span class="badge position-absolute bottom-0 end-0 badge-position">
                <img
                    src={IcCamera}
                    class="img-thumbnail-sm rounded-circle"
                    width="100%"
                    height="auto"
                    alt="camera"
                    onClick={handleClickInput}
                ></img>
            </span>
        </div>
    );
};

export default FotoProfileHandler;
