import { Dialog, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2';

const ModalAlasanDitolak = ({
    openDitolak,
    handleCloseDitolak,
    handleAlasanDitolak,
    alasan,
    handleUpdateStatus,
    selectedItem
}) => {
    const useStyles = makeStyles({
        dialog: {
            width: '380px',
            height: 'fit-content',
            scrollbarColor: 'transparent',
            padding: '12px'
        },
        backDrop: {
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
        }
    });
    const classes = useStyles();

    return (
        <Dialog
            open={openDitolak}
            onClose={handleCloseDitolak}
            classes={{
                paper: classes.dialog
            }}
            BackdropProps={{
                classes: {
                    root: classes.backDrop
                }
            }}
            style={{
                overflow: 'hidden !important',
                margin: 0
            }}
        >
            <div>
                <h1 className="dialog-ditolak-title">
                    Masukkan Alasan Ditolak
                </h1>
                <Grid container>
                    <Grid item xs={12}>
                        <p
                            style={{
                                marginBottom: '-3px'
                            }}
                        >
                            Alasan Ditolak
                        </p>
                        <textarea
                            required={true}
                            name="alasan"
                            rows="3"
                            cols="50"
                            placeholder="Alasan"
                            className="dialog-ditolak-input"
                            onChange={handleAlasanDitolak}
                            autoFocus
                        />
                    </Grid>

                    <div className="dialog-button-container">
                        <p
                            className="dialog-button dialog-button-batal"
                            onClick={handleCloseDitolak}
                        >
                            Batal
                        </p>

                        <p
                            className="dialog-button dialog-button-kirim"
                            onClick={() => {
                                if (alasan) {
                                    handleUpdateStatus(
                                        selectedItem,
                                        'ditolak'
                                    );
                                } else {
                                    Swal.fire({
                                        title: 'Error',
                                        text: 'Alasan tidak boleh kosong!',
                                        icon: 'error',
                                        timer: 2000,
                                        showConfirmButton: false
                                    });
                                }
                            }}
                        >
                            Kirim
                        </p>
                    </div>
                </Grid>
            </div>
        </Dialog>
    );
};

export default ModalAlasanDitolak;
