export const formatRupiah = (angka = 0, prefix, noPrefix) => {
    if (angka !== undefined) {
        const number_string = angka.toString().replace(/[^,\d]/g, "");
        const split = number_string.split(",");
        const sisa = split[0].length % 3;
        let rupiah = split[0].substr(0, sisa);
        const ribuan = split[0].substr(sisa).match(/\d{3}/gi);
        if (ribuan) {
            const separator = sisa ? "." : "";
            rupiah += separator + ribuan.join(".");
        }
        rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
        if (!noPrefix) {
            if (angka === 0) {
                rupiah = 0;
            }
            let result =
                prefix === undefined ? "Rp " + rupiah : rupiah ? prefix + rupiah : "";
            return angka < 0 ? "-" + result : result;
        } else {
            let result = angka < 0 ? "-" + rupiah : rupiah;
            return result;
        }
    } else {
        return 0;
    }
};
