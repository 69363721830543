import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import '../../assets/style/mobile-size.css';

import {
    IcHome,
    IcProfile,
    IcNavBell,
    IcDocument,
    IcWallet,
} from '../../assets';

const MobileNavigation = ({ user }) => {
    const [path, setPath] = useState('');

    useEffect(() => {
        const path = window.location.pathname;
        setPath(path);
    }, []);

    return (
        <div className="position-fixed menu-mobile border ">
            <Container className="d-flex align-items-center justify-content-between px-4">
                <div
                    className={
                        path === '/' ? 'navbar-active-menu' : 'navbar-menu'
                    }
                >
                    <Link
                        to="/"
                        className="d-flex flex-column align-items-center justify-content-center item-menu "
                    >
                        <img
                            alt="icon menu"
                            src={IcHome}
                            className={
                                path === '/' ? 'icon-active-color' : null
                            }
                        />
                        <p
                            className={`m-0 ${path === '/' ? 'text-primaryy' : ''
                                }`}
                        >
                            Beranda
                        </p>
                    </Link>
                </div>

                <div
                    className={
                        path === '/izin_cuti'
                            ? 'navbar-active-menu'
                            : 'navbar-menu'
                    }
                >
                    <Link
                        to="/izin_cuti"
                        className="d-flex flex-column align-items-center justify-content-center item-menu "
                    >
                        <img
                            alt="icon menu"
                            src={IcDocument}
                            className={
                                path === '/izin_cuti'
                                    ? 'icon-active-color'
                                    : null
                            }
                        />
                        <p
                            className={`m-0 ${path === '/izin_cuti'
                                    ? 'text-primaryy'
                                    : ''
                                }`}
                        >
                            Izin Cuti
                        </p>
                    </Link>
                </div>

                <div
                    className={
                        path === '/gaji' ? 'navbar-active-menu' : 'navbar-menu'
                    }
                >
                    <Link
                        to="/gaji"
                        className="d-flex flex-column align-items-center justify-content-center item-menu "
                    >
                        <img
                            alt="icon menu"
                            src={IcWallet}
                            className={
                                path === '/gaji' ? 'icon-active-color' : null
                            }
                        />
                        <p
                            className={`m-0 ${path === '/gaji' ? 'text-primaryy' : ''
                                }`}
                        >
                            Gaji
                        </p>
                    </Link>
                </div>

                <div
                    className={
                        path === '/notifikasi' ? 'navbar-active-menu' : 'navbar-menu'
                    }
                >
                    <Link
                        to="/notifikasi"
                        className="d-flex flex-column align-items-center justify-content-center item-menu "
                    >
                        <img
                            alt="icon menu"
                            src={IcNavBell}
                            className={
                                path === '/notifikasi' ? 'icon-active-color' : null
                            }
                        />
                        <p
                            className={`m-0 ${path === '/notifikasi' ? 'text-primaryy' : ''
                                }`}
                        >
                            Notifikasi
                        </p>
                    </Link>
                </div>

                <div
                    className={
                        path === '/akun' ? 'navbar-active-menu' : 'navbar-menu'
                    }
                >
                    <a
                        href="/akun"
                        className="d-flex flex-column align-items-center justify-content-center item-menu"
                    >
                        <img
                            alt="icon menu"
                            src={IcProfile}
                            className={
                                path === '/akun' ? 'icon-active-color' : null
                            }
                        />
                        <p className={`m-0 ${path === '/akun' ? 'text-primaryy' : ''}`}>Akun</p>
                    </a>
                </div>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user.user
    };
};

export default connect(mapStateToProps, null)(MobileNavigation);
