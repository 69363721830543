import { useEffect, useState } from "react";
import { formatDateFns } from "../utils/formatDateFns";
import { getQueryParameter } from '../utils/getQueryParameter';

const useDateFilter = () => {
    const [date, setDate] = useState(() => {
        const savedDate = window.localStorage.getItem('date_filter');

        if (!savedDate) {
            const currentDate = new Date();
            window.localStorage.setItem('date_filter', formatDateFns(currentDate, 'yyyy-MM-dd'));
            return currentDate;
        }

        return new Date(savedDate);
    });

    useEffect(() => {
        const bulanTahun = getQueryParameter('bulan_tahun');
        if (bulanTahun) {
            const [bulan, tahun] = bulanTahun.split('-');
            const newDate = new Date(+tahun, +bulan - 1);

            if (formatDateFns(newDate, 'yyyy-MM-dd') !== formatDateFns(date, 'yyyy-MM-dd')) {
                setDate(newDate);
            }
        }
    }, [date]);

    useEffect(() => {
        window.localStorage.setItem('date_filter', formatDateFns(date, 'yyyy-MM-dd'));
    }, [date]);

    return [date, setDate];
}

export default useDateFilter;