import axios from 'axios';

// Set config defaults when creating the instance
export const URL = process.env.REACT_APP_BASE_BACKEND_URL || "http://127.0.0.1:8000";

export const API = axios.create({
    baseURL: `${URL}/api/v1/`
});

export const setAuthToken = (token) => {
    API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    API.defaults.headers.common['Content-Type'] =
        'application/x-www-form-urlencoded';
    API.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
};
