import '../../assets/style/homepage.css';

import DateFnsUtils from '@date-io/date-fns';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { id } from 'date-fns/locale';
import { IcChevronLeftCircle, IcChevronRightCircle } from '../../assets';
import { addMonths, subMonths } from 'date-fns';

const MonthYearPicker = ({ date, setDate }) => {
    return (
        <div className="absent-info-container">
            <div
                className="ic-btn"
                onClick={() => setDate((prev) => subMonths(prev, 1))}
            >
                <img src={IcChevronLeftCircle} alt="prev" />
            </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={id}>
                <KeyboardDatePicker
                    autoOk
                    showTodayButton
                    todayLabel="Hari Ini"
                    cancelLabel="Batal"
                    color="primary"
                    inputVariant="outlined"
                    format="MMMM yyyy"
                    value={date}
                    InputProps={{ readOnly: true }}
                    InputAdornmentProps={{ position: 'end' }}
                    onChange={setDate}
                    size="small"
                    title="Ganti untuk merubah tanggal yang diperbolehkan"
                />
            </MuiPickersUtilsProvider>
            <div
                className="ic-btn"
                onClick={() => setDate((prev) => addMonths(prev, 1))}
            >
                <img src={IcChevronRightCircle} alt="next" />
            </div>
        </div>
    );
};

export default MonthYearPicker;
