import {
    Button,
    Checkbox,
    CircularProgress,
    IconButton,
    InputAdornment
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {
    IcEyeClose,
    IcEyeOpen,
    IcUsername,
    IcPass,
    IlNotification
} from '../../assets';
import { login } from '../../store/actions/authAction';
import { decryptAes, encryptAes } from '../../utils/encryptAes';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#9e9e9e' // set the primary color to grey
        }
    }
});

const Login = ({ history, login }) => {
    const [state, setstate] = useState({
        email: '',
        password: '',
        agree: false
    });
    const [submit, setSubmit] = useState(false);
    const [showPass, setshowPass] = useState(false);
    const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState(null);

    const handleSubmit = () => {
        setSubmit(true);
        login({
            email: state.email,
            password: state.password
        })
            .then((res) => {
                localStorage.setItem('rememberMe', state.agree);
                localStorage.setItem(
                    'verification_status',
                    res.value.authenticated
                );
                localStorage.setItem(
                    'complete_profile',
                    res.value.complete_profile
                );
                if (state.agree) {
                    localStorage.setItem('emailUser', encryptAes(state.email));
                    localStorage.setItem('psUser', encryptAes(state.password));
                } else {
                    localStorage.removeItem('emailUser');
                    localStorage.removeItem('psUser');
                }
                const name = res.value.user.name;
                console.log(res);
                Swal.fire({
                    title: 'Success',
                    text: `Welcome ${name}`,
                    icon: 'success',
                    iconColor: '#00ACEE',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    history.push('/');
                    setSubmit(false);
                });
            })
            .catch((error) => {
                console.log(error);
                const err = error.response.data;
                if (err.code === 2) {
                    Swal.fire({
                        title: 'Error',
                        text: err.message,
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: false
                    });
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: 'Login failed, try again',
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: false
                    });
                }
                setSubmit(false);
            });
    };

    const handleChange = ({ target }) => {
        setstate((pref) => ({
            ...pref,
            [target.name]: target.value
        }));
    };

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        document.title = 'Alan Creative | Login';
        let rememberMe = localStorage.getItem('rememberMe');
        let emailUser = localStorage.getItem('emailUser');
        let psUser = localStorage.getItem('psUser');
        if (rememberMe === 'true') {
            setstate((pref) => ({
                ...pref,
                email: decryptAes(emailUser),
                password: decryptAes(psUser),
                agree: true
            }));
        }

        const handler = (e) => {
            e.preventDefault();
            console.log('we are being triggered :D');
            setSupportsPWA(true);
            setPromptInstall(e);
        };
        window.addEventListener('beforeinstallprompt', handler);

        return () => window.removeEventListener('transitionend', handler);
    }, []);

    const installApp = (evt) => {
        evt.preventDefault();
        if (!promptInstall) {
            return;
        }
        promptInstall.prompt();
    };

    return (
        <section className="container-auth bg-auth">
            {/* <div className="container-ilustration flex-column align-items-center p-4">
                <div className="wrap-content">
                    <img alt="signup" src={IlLoginSession} className="w-35" />
                </div>
            </div> */}

            <Container>
                <Row className="justify-content-center">
                    <Col lg="9" md="9" sm="12" xs="12">
                        <img
                            src={IlNotification}
                            alt="ic-brand"
                            className="d-flex mx-auto mt-5"
                            width={250}
                            height={250}
                        />
                        <div className="text-center mt-4">
                            <h3>
                                <b>Selamat Datang di Alan HR Mobile</b>
                            </h3>
                            <h6>Masuk sebagai HRD Alan Creative</h6>
                        </div>

                        <ValidatorForm
                            onSubmit={handleSubmit}
                            className="form mt-3"
                        >
                            <label htmlFor="email" className="label text-white">
                                Email
                            </label>
                            <TextValidator
                                variant="outlined"
                                placeholder="Masukkan email"
                                type="text"
                                onChange={handleChange}
                                name="email"
                                id="email"
                                value={state.email}
                                validators={['required']}
                                errorMessages={[
                                    'this field is required',
                                    'email is not valid'
                                ]}
                                className="w-100 mb-3"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img
                                                alt="full name"
                                                src={IcUsername}
                                            />
                                        </InputAdornment>
                                    )
                                }}
                            />

                            <label
                                htmlFor="password"
                                className="label text-white"
                            >
                                Password
                            </label>
                            <TextValidator
                                variant="outlined"
                                placeholder="Masukkan kata sandi"
                                onChange={handleChange}
                                name="password"
                                id="password"
                                value={state.password}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                className="w-100 mb-3"
                                type={showPass ? 'text' : 'password'}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img alt="password" src={IcPass} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() =>
                                                    setshowPass(!showPass)
                                                }
                                            >
                                                <img
                                                    alt="password"
                                                    src={
                                                        showPass
                                                            ? IcEyeClose
                                                            : IcEyeOpen
                                                    }
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />

                            <div className="mb-3 d-flex flex-row justify-content-between align-items-center text-forgot">
                                <div>
                                    <ThemeProvider theme={theme}>
                                        <Checkbox
                                            name="remember"
                                            id="remember"
                                            // className="text-white"
                                            onChange={({ target }) =>
                                                setstate((pref) => ({
                                                    ...pref,
                                                    agree: target.checked
                                                }))
                                            }
                                            checked={state.agree}
                                        />
                                    </ThemeProvider>
                                    <label htmlFor="remember" className="m-0">
                                        Remember me
                                    </label>
                                </div>
                            </div>
                            <Button
                                color="primary"
                                style={{ color: 'white' }}
                                type="submit"
                                variant="contained"
                                className="mb-4 w-100 py-2 btn-auth"
                                disabled={submit}
                                // onClick={handleSubmit}
                            >
                                {submit ? (
                                    <CircularProgress
                                        size={24}
                                        className="text-salmon"
                                    />
                                ) : (
                                    'Masuk'
                                )}
                            </Button>
                            {supportsPWA && (
                                <Button
                                    color="primary"
                                    type="button"
                                    variant="outlined"
                                    className="mb-4 w-100 py-2 btn-auth"
                                    onClick={installApp}
                                    disabled={submit}
                                >
                                    Install
                                </Button>
                            )}
                        </ValidatorForm>
                        {/* <h3 className="text-center text-forgot mb-100">
                            <Link to="/forgot_password">Lupa kata sandi?</Link>
                        </h3> */}
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (params) => dispatch(login(params))
    };
};

export default connect(null, mapDispatchToProps)(Login);
